@media only screen and (max-width:1630px) {
	header nav ul>li {
		margin:0 15px;
	}
}

@media only screen and (max-width:1400px) {
	body {
		margin-top: 75px !important;
		&.overflowed {
			overflow: hidden;
		}
	}
	header {
	    height: 75px;
		a.logo {
			img {
				max-height:55px;
				margin-top: 10px;
			}
			img.logo-mark {
				margin-top: 15px;
			}
		}
		nav {
			ul {
				>li {
					margin:0 10px;
					&.mega-menu-item-has-children {
						a {
							padding-right: 15px;
						}
					}
				}
			}
			span.search-trigger {
				margin:0 20px;
			}
		}
	}





	.hero-slider {
		.slide {
			height:450px;
		}
		.content {
			font-size:24px;
			p {
				font-size:24px;
			}
		}
	}
}

@media only screen and (max-width:1200px) {
	header {
		.responsive-nav {
			display:block;
		}
		nav {
			position:fixed;
			display:block;
			top:75px;
			left:0;
			right:0;
			bottom:0;
			background:#fff;
			overflow:scroll;
			padding-bottom: 40px;
			transition:opacity 0.3s;
			visibility: hidden;
			&.active {
				visibility: visible;
				opacity:1;
			}
			a.button {
				display:block;
				margin:0 35px;
				border-radius:0;
			}
			span.search-trigger {
				display:none;
			}
			ul {
				li {
					&.widget_media_image, &.widget_custom_html {
						display:none;
					}
					&.widget_nav_menu {
						width:100% !important;
						padding-left: 0 !important;
						border-left: none !important;
						float:none !important;
					}
					&.mega-toggle-on {
						> ul {
							display:block;
						}
					}
					a {
						padding:10px 0;
						line-height:normal !important;
					}
				}
			}
			ul {
				> li {
					display:block;
					border-bottom: 1px solid #eaeaea !important;
					font-size: 14px !important;
					&.mega-menu-item-has-children:before {
						top:19px;
						pointer-events: none;
					}
				}
				ul.mega-sub-menu {
					border:none !important;
					width:100% !important;
					opacity:1;
					visibility: visible;
					position:static;
					width:auto !important;
					transform:none !important;
					box-shadow:none !important;
					display:none;
					padding:0 !important;
				}
			}
		}
	}



	.the-team {
		.tabs {
			.tab {
				width:33.1%;
				text-align: center;
				border-radius:0;
				margin:1px 1px 0 0;
				font-size:14px;
			}
		}
	}
}

@media only screen and (max-width:1000px) {
	.home-news-events {
		.box {
			width:50%;
		}
	}
	.our-members-slider {
		.content {
			width:100%;
		}
		.slider {
			width:100%;
		}
	}
	footer {
		.top {
			.column {
				width:50%;
				&:first-child {
					padding-right: 0;
					width:100%;
					margin-bottom: 30px;
					p:first-child {
						margin:0;
						img {
							display:none;
						}
					}
				}
			}
		}
		.bottom {
			.right {
				padding-right: 20px;
			}
		}
	}
	.column-section {
		&.four-column {
			.column {
				width:50%;
			}
		}
	}
	.more-posts {
		.box {
			width:50%;
		}
	}
	.the-team {
		.tab-content-wrapper {
			.tab-content {
				.container {
					.box {
						width:50%;
					}
				}
			}
		}
	}
	.two-column-big-image {
		.image {
			position:static;
			height:0;
			padding-bottom: 56%;
			width:100%;
		}
		.container {
			padding:0 !important;
			width:auto;
			float:none !important;
		}
	}
}

@media only screen and (max-width:800px) {
	.two-column-text-video {
		.content {
			width:100%;
			padding-right: 0;
		}
		.video {
			width:100%;
		}
	}
	.breadcrumbs {
		padding:40px 0;
	}
	.shared-value, .footprint {
		.left {
			width:100%;
			padding-right: 0;
		}
		.right {
			width:100%;
			img {
				max-height:400px;
				width:auto;
			}
		}
	}
	.shared-value-initiative {
		.container {
			.box {
				width:100%;
			}
		}
	}
	.impact {
		.impacts {
			.col20 {
				width:33.3333%;
			}
		}
	}
	.footprint-impact {
		.left {
			width:100%;
			h2 {
				text-align: center;
				margin-bottom: 20px;
			}
		}
		.right {
			width:100%;
			text-align: center;
			&:before {
				content: "";
			    display: block;
			    position: absolute;
			    right: 100%;
			    top: 0;
			    bottom: 0;
			    width: 900%;
			    background: #f05b31;
			}
		}
	}
	.cta-banner {
		padding:30px 0;
		p {
			font-size:22px;
			line-height:1.4;
		}
	}
	.members-list {
		.member-category {
			margin:0 -10px;
			.member {
				padding:10px;
				img {
					max-width:140px;
				}
			}
		}
	}
	.membership-engagement {
		.left,.right {
			width:100%;
		}
		.right {
			margin-top: -1px;
		}
	}
	img {
		&.alignright, &.alignleft {
			float:none;
		}
	}
	section div.wpcf7 .row .col-md-6 {
		width:100%;
	}
	.featured-event {
		.container {
			display:block !important;
		}
		.image {
			width:100%;
			padding-bottom: 65%;
			margin-bottom: 20px;
			.img-resize {
				right:0;
			}
		}
		.content {
			padding-left: 0;
			width:100%;
		}
	}
	.more-posts {
		.box {
			width:100%;
		}
	}
	.the-team {
		.tabs {
			.tab {
				width:auto;
				float:none;
				padding:8px 10px;
			}
		}
	}
	.filters {
		.filters-wrapper {
			.filters-toggle {
				display:block;
			}
			.filters-container {
				display:none;
				.selectric-wrapper {
					margin:2px 0;
					width:100%;
				}
			}
		}
	}
}

@media only screen and (max-width:600px) {
	html {
		font-size:40%;
	}
	.pageAlign, .pageAlign2, .pageAlignFluid {
		padding:0 10px;
	}
	.impact {
		.impacts {
			.col20 {
				width:50%;
			}
		}
	}
	.hero-slider {
		.slide {
			height:350px;
		}
		.content {
			font-size:18px;
			padding:0 30px;
			p {
				font-size:18px;
			}
			br {
				display:none;
			}
		}
		.slick-next {
			right:5px;
		}
		.slick-prev {
			left:5px;
		}
	}
	.home-news-events {
		.box {
			width:100%;
		}
	}
	footer {
		.top {
			.column {
				width:100%;
				padding-right: 0;
			}
		}
		.bottom {
			.left {
				width:100%;
			}
			.right {
				margin-top: 10px;
				width:100%;
				padding-right: 0;
				a:first-child {
					margin-left: 0;
				}
			}
		}
	}
	.shared-value-awards {
		.content {
			.award {
				.image {
					width:50%;
					padding-bottom: 35%;
				}
				.award-content {
					padding-left: 0;
					width:100%;
				}
			}
		}
	}
	.column-section {
		&.four-column {
			.column {
				width:100%;
			}
		}
		&.three-column {
			.column {
				width:100%;
			}
		}
		&.two-column {
			.column {
				width:100%;
				&:before {
					left:-500px !important;
					right:-500px !important;
					width: auto !important;
				}
			}
		}
	}
	section div.wpcf7 .row .col-md-5, section div.wpcf7 .row .col-md-2, {
		width:100%;
	}
	.single-post-wrapper {
		.single-post-content {
			width:auto;
		}
	}
	.the-team {
		.tab-content-wrapper {
			.tab-content {
				.container {
					.box {
						width:100%;
					}
				}
			}
		}
	}
}
