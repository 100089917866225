@import "settings";

img {
	height:inherit;
}



body {
	color:#666;
	font-size:14px;
	p {
		line-height:26px;
	}
	h1,h2,h3,h4,h5 {
		color:inherit;
		line-height:normal;

	}
	h2,h3,h4 {
		color:#111;
	}
	&.research {
		.column-section {
			.container {
				display:flex;
				flex-wrap:wrap;
			}
		}
	}
}

h2.fancy {
	font-weight:700;
	position:relative;
	padding-bottom: 20px;
	&:after {
		content:"";
		display:block;
		position:absolute;
		bottom:0;
		left:0;
		width:82px;
		height:7px;
		background:url(../images/heading-accent.svg) no-repeat center center;
		background-size:contain;
	}
}
h2.fancy-white {
	font-weight:700;
	position:relative;
	padding-bottom: 20px;
	&:after {
		content:"";
		display:block;
		position:absolute;
		bottom:0;
		left:0;
		width:82px;
		height:7px;
		background:url(../images/heading-accent-white.svg) no-repeat center center;
		background-size:contain;
	}
}
h2.fancy-center {
	font-weight:700;
	position:relative;
	padding-bottom: 20px;
	&:after {
		content:"";
		display:block;
		position:absolute;
		bottom:0;
		left:50%;
		transform:translateX(-50%);
		width:82px;
		height:7px;
		background:url(../images/heading-accent.svg) no-repeat center center;
		background-size:contain;
	}
}
h2.fancy-white-center {
	font-weight:700;
	position:relative;
	padding-bottom: 20px;
	&:after {
		content:"";
		display:block;
		position:absolute;
		bottom:0;
		left:50%;
		transform:translateX(-50%);
		width:82px;
		height:7px;
		background:url(../images/heading-accent-white.svg) no-repeat center center;
		background-size:contain;
	}
}
/*
body.category-case-studies {
	.home-news-events {
		.container {
			margin:30px 0 0 0;
		}
		.box {
			width:25%;
			border: 1px solid #ccc;
			margin: 0 -1px -1px 0;
			.title {
				text-align: center;
				font-size:16px;
			}
		}
	}
}
*/

a.button, button.button, input[type="submit"] {
	background:$link-color;
	display:inline-block;
	margin:20px 10px 10px 0;
	padding:12px 40px;
	color:#fff;
	text-transform: uppercase;
	font-size:14px;
	transition:all 0.3s;
	border-radius:30px;
	position:relative;
	-webkit-appearance:none;
	border:none;
	cursor:pointer;
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:50%;
		right:20px;
		background:url(../images/arrow-white.svg) no-repeat center center;
		background-size:contain;
		transform:translateY(-50%);
		width:13px;
		height:13px;
	}
	&:hover {
		background:darken($link-color, 10%);
	}
	&.type2 {
		background:transparent;
		color:$link-color;
		box-shadow: inset 0px 0px 0px 1px rgba(240,91,49,1);
		&:after {
			background:url(../images/arrow-orange.svg) no-repeat center center;
			background-size:contain;
		}
		&:hover {
			background:$link-color;
			color:#fff;
			&:after {
				background:url(../images/arrow-white.svg) no-repeat center center;
				background-size:contain;
			}
		}
	}
	&.type3 {
		background:#fff;
		color:$link-color;
		&:after {
			background:url(../images/arrow-orange.svg) no-repeat center center;
			background-size:contain;
		}
		&:hover {
			color:$font-color;
		}
	}
}


.divider {
	padding:0;
	margin:0;
	.divide {
		height:2px;
	}
}



.container {
	@include clearfix;
}

header {
	position:fixed;
	top:0;
	left:0;
	right:0;
	background:#fff;
	height:130px;
	z-index:12;
	transition:all 0.3s;
	.pageAlignFluid {
		position:relative;
		z-index:9;
		background:#fff;
	}
	&.scrolling {
		height:75px;
		box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
		a.logo {
			img {
				display:none;
			}
			img.logo-mark {
				height:40px;
				display:block;
			}
		}
		nav {
			ul {
				li {
					a {
						line-height:75px;
					}
					ul.mega-sub-menu {
						border-top: none;
					}
				}
			}
		}
	}
	.container {
		display:flex;
		flex-wrap:wrap;
		align-items: center;
	}
	a.logo {
		display:block;
		margin-right: 50px;
		img {
			height:65px;
			width:auto;
		}
		img.logo-mark {
			display:none;
		}
	}
	.mega-menu-toggle {
		display:none !important;
	}
	nav {
		margin-left:auto;
		display:flex;
		align-self:stretch;
		ul {
			margin:0;
			padding:0;
			> li {
				height:100%;
				position:relative;
				display:inline-block;
				margin:0 35px;
				position:relative;
				&.mega-menu-item-has-children {
					> a {
						padding-right: 25px;
					}
					&:before {
						content:"";
						display:block;
						position:absolute;
						top:50%;
						right:0;
						transform:translateY(-50%) rotate(90deg);
						background:url(../images/arrow-black.svg) no-repeat center center;
						background-size:contain;
						width:10px;
						height:10px;
						opacity:0.3;
						transition:all 0.3s;
					}
				}
				&.mega-menu-flyout {
					ul.mega-sub-menu {
						width:200px;
						padding:0;
						right:auto;
						left:50%;
						transform:translateX(-50%);
						border-top:none;
						li {
							border-bottom: 1px solid #eaeaea;
							font-size:14px;
							&:hover {
								a {
									background:#fdfdfd;
								}
							}
							a {
								padding:15px;
							}
						}
					}
				}
				&:after {
					content:"";
					display:block;
					position:absolute;
					bottom:0;
					left:0;
					right:0;
					height:3px;
					background:$link-color;
					transform:scaleX(0);
					transition:all 0.3s;
				}
				&.current-menu-item, &.current-cat {
					a {
						color:$link-color;
					}
				}
				&:hover {
					&:after {
						transform:scaleX(1);
					}
					> a {
						color:$link-color;
					}
					> ul {
						opacity: 1;
						visibility: visible;
					}
					&:before {
						opacity:.7;
						transform: translateY(-50%) rotate(-90deg);
					}
				}
				a {
					display:block;
					height:100%;
					color:$font-color;
					text-transform: uppercase;
					font-weight:600;
					line-height:130px;
					transition:all 0.3s;
				}
				> ul{
					background:#fff;
					display:block;
					position:absolute;
					top:100%;
					left:50%;
					transform:translateX(-50%);
					transition:all 0.3s;
					opacity:0;
					visibility: hidden;
					li {
						height:auto;
						margin:0;
						display:block;
						a {
							height:auto !important;
							line-height:normal !important;
							font-size:25px 10px;
							font-weight:400;
							padding:10px;
							text-transform: none;
						}
					}
				}
				ul.mega-sub-menu {
					border-top:2px solid #cbcbcb;
					width:700px;
					padding:40px;
					box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
					li.widget_media_image {
						float:left;
						width:50%;
						padding-right:50px;
						img {
							min-width:100%;
						}
					}
					li.widget_categories {
						padding-left:50px;
						float:right;
						width:50%;
						border-left:2px solid #cbcbcb;
						h4 {
							display:none;
						}
					}
					li.widget_nav_menu {
						padding-left:50px;
						float:right;
						width:50%;
						border-left:2px solid #cbcbcb;
						h4 {
							display:none;
						}
					}
					li.widget_custom_html {
						float:left;
						width:50%;
						padding-right:50px;
						h4 {
							color:$link-color;
							font-size:20px;
							margin-bottom: 10px;
						}
						.textwidget {
							font-size:14px;
							color:#333333;
							line-height:20px;
						}
					}
					li {
						&:after{
							display:none;
						}
						ul {
							position:static;
							opacity: 1;
							visibility: inherit;
							transform:none;
						}
					}
				}
			}
		}
		a.button {
			height:auto;
			line-height:normal;
			align-self:center;
			margin:0;
		}
		span.search-trigger {
			height:auto;
			line-height:normal;
			align-self:center;
			margin:0;
			width:25px;
			height:25px;
			background:url(../images/icon-search.svg) no-repeat center center;
			background-size:contain;
			cursor:pointer;
			transition:opacity 0.3s;
			margin:0 70px 0 30px;
			&:hover {
				opacity:0.7;
			}
			&.active {
				background-image:url(../images/icon-close.svg);
			}
		}
	}
	.header-search {
		background:#fff;
		padding:20px 0;
		transform:translateY(-80px);
		transition:all 0.5s ease-in-out;
		border-top:1px solid #ddd;
		background:#fbfbfb;
		opacity:0;
		visibility: hidden;
		.pageAlignFluid {
			background:transparent;
		}
		&.active {
			opacity:1;
			visibility: visible;
			transform:translateY(0);
		}
		.container {
			display:block;
		}
		form {
			float:right;
			width:100%;
			input {
				float:right;
				border:1px solid #ddd;
				background:#fff;
				padding: 12px 40px;
				font-size:16px;
				width:100%;
				max-width:500px;
				border-radius:30px;
				height:42px;
				line-height:42px;
				&:focus {
					border-color:#ccc;
				}
			}
			button {
				float:right;
				margin:0 0 0 -40px;
				border:1px solid $link-color;
				cursor:pointer;
			}
		}
	}
}

.hero-slider {
	overflow: hidden;
	position:relative;
	&:after {
		content:"";
		display:block;
		position:absolute;
		bottom:0;
		right:0;
		width:128px;
		height:103px;
		background:url(../images/hero-slider-mask.png) no-repeat bottom right;
		background-size:contain;
	}
	.slide {
		height:550px;
		position:relative;
	}
	.image {
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		transform:scale(1.1);
		transition:transform 15s;
		background:no-repeat center center;
		background-size:cover;
		&.animate {
			transform:scale(1);
		}
		.image-overlay {
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			opacity:0.3;
		}
	}
	.content {
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		z-index:1;
		display:flex;
		align-items: center;
		color:#fff;
		font-size:32px;
		font-weight:600;
		transform:translateX(-100px);
		opacity:0;
		transition:all 0.7s ease-in-out;
		&.content-reveal {
			opacity:1;
			transform:translateX(0);
		}
		p {
			line-height:1.3;
		}
		blockquote {
			margin:0;
			padding:0;
			font-size:14px;
		}
		a.button {
			text-shadow:none;
		}
		.pageAlign {
			width:100%;
		}
	}
	.slick-prev, .slick-next {
		overflow: hidden;
		text-indent:-900000px;
		background:url(../images/arrow-white.svg) no-repeat center center;
		background-size:contain;
		width:30px;
		height:50px;
		z-index:3;
		transition:opacity 0.3s;
		&:hover {
			opacity:0.7;
		}
		&:before {
			display:none;
		}
	}
	.slick-next {
		right:20px;
	}
	.slick-prev {
		left:20px;
		transform:scaleX(-1);
	}
}

.two-column-text-video {
	.top {
		color:$link-color;
		font-size:18px;
	}
	.main {
		padding:30px 0;
		@include clearfix;
		display:flex;
		flex-wrap:wrap;
		align-items: center;
	}
	.content {
		float:left;
		width:50%;
		padding-right: 100px;
		h3 {
			color:$link-color;
			font-size:16px;
			font-weight:700;
		}
		h2 {
			font-weight:700;
			position:relative;
			padding-bottom: 20px;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:0;
				left:0;
				width:82px;
				height:7px;
				background:url(../images/heading-accent.svg) no-repeat center center;
				background-size:contain;
			}
		}
	}
	.video {
		float:right;
		width:50%;
		.youtube-wrapper {
			height:0;
			padding-bottom: 56.25%;
			background-size:cover;
			position:relative;
			border-bottom-right-radius: 50px;
			overflow: hidden;
			.play {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				&:before {
					content:"";
					display:block;
					position:absolute;
					width:80px;
					height:80px;
					top:50%;
					left:50%;
					background:#fff;
					border-radius:100%;
					transform:translate(-50%, -50%);
					z-index:2;
				}
				&:after {
					content:"";
					display:block;
					position:absolute;
					width:80px;
					height:80px;
					top:50%;
					left:50%;
					background:$link-color;
					border-radius:100%;
					margin:-40px 0 0 -40px;
					animation: play-pulse 2s infinite;
					z-index:1;
				}
				@keyframes play-pulse {
					0%   { opacity: 1; transform:scale(1); }
					100% { opacity: 0; transform:scale(2)}
				}
				.play-icon {
					display:block;
					position:absolute;
					width:25px;
					height:25px;
					top:50%;
					left:50%;
					background:url(../images/play.svg) no-repeat center center;
					background-size:contain;
					transform:translate(-50%, -50%);
					z-index:3;
				}
			}
		}
	}
}

.member-benefits-slider {
	padding:40px 0;
	h2 {
		text-transform: uppercase;
		font-weight:700;
		position:relative;
		padding-bottom: 20px;
		&:after {
			content:"";
			display:block;
			position:absolute;
			bottom:0;
			left:0;
			width:82px;
			height:7px;
			background:url(../images/heading-accent.svg) no-repeat center center;
			background-size:contain;
		}
	}
	.slider {
		padding-top: 30px;
		position:relative;
		&:after, &:before {
			content:"";
			display:block;
			position:absolute;
			top:0;
			bottom:0;
			right:0;
			width:200px;
			background: rgb(255,255,255);
			background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			z-index:3;
		}
		&:before {
			left:0;
			right:auto;
			background: rgb(255,255,255);
			background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
		}
		.slide {
			width:160px;
			text-align: center;
			margin:0 30px;
			.image {
				margin:0 auto;
				width:100px;
				height:100px;
				display:flex;
				justify-content: center;
				align-items: center;
				img {
					max-height:100%;
				}
			}
			.text {
				color:$blue-color;
			}
		}
	}
}

.archive .home-news-events {
	background:#fff;
	.box {
		.image {
			background:#f6f6f6;
		}
	}
}
.home-news-events {
	overflow: hidden;
	background:#f6f6f6;
	.top {
		h2 {
			font-weight:700;
			position:relative;
			padding-bottom: 20px;
			text-transform: uppercase;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:0;
				left:0;
				width:82px;
				height:7px;
				background:url(../images/heading-accent.svg) no-repeat center center;
				background-size:contain;
			}
		}
	}
	.container {
		display:flex;
		flex-wrap:wrap;
		margin:0 -20px;
	}
	.box {
		&.type-event {
			.image {
				.img-resize {
					left:78px;
				}
			}
		}
		float:left;
		width:33.3333%;
		padding:20px 20px 50px 20px;
		a, span {
			display:block;
		}
		.image {
			height:0;
			padding-bottom: 56.25%;
			position:relative;
			background:#fff;
			overflow: hidden;
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background:url(../images/logo-mark.svg) no-repeat center center;
				background-size:50px auto;
				transition:all 0.3s;
			}
			.img-resize {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				z-index:1;
				overflow: hidden;
				img {
					max-width:none;
					transition:transform .7s;
				}
			}
			.event-info {
				position:absolute;
				top:0;
				left:0;
				bottom:0;
				width:78px;
				background:$link-color;
				z-index:2;
				color:#fff;
				text-align: center;
				color:#fff;
				display:flex;
				align-items: flex-start;
				flex-wrap: wrap;
				&.online {
					background:$blue-color;
					.event-icon {
						background-image:url(../images/icon-event-web.svg);
					}
				}
				&.standard {
					background:$link-color;
					.event-icon {
						background-image:url(../images/icon-event-standard.svg);
					}
				}
				&.both {
					background:#6ab961;
					.event-icon {
						background-image:url(../images/icon-event-both.svg);
					}
				}
				.date {
					width:100%;
				}
				.event-icon {
					width:100%;

				}
				.day {
					font-size:30px;
				}
				.month {
					text-transform: uppercase;
					font-size:15px;
				}
				.year {
					font-size:13px;
					border-top: 1px solid #fff;
					padding-top: 4px;
					margin-top: 4px;
				}
			}
			.event-icon {
				margin-top:auto;
				height:0;
				padding-bottom: 60%;
				margin-bottom: 5px;
				background:yellow;
				position:relative;
				background:no-repeat top center;
				background-size:auto 85%;
			}
			&:hover {
				&:after {
					background-size:70px auto;
				}
				img {
					transform:scale(1.1);
				}
			}
		}
		.title {
			color:$font-color;
			font-size:20px;
			font-weight:600;
			padding-top: 25px;
			transition:all 0.3s;
			text-transform: uppercase;
			font-size:16px;
			&:hover {
				color:$link-color;
			}
		}
	}
}

.our-members-slider {
	h2 {
		font-weight:700;
		position:relative;
		padding-bottom: 20px;
		text-transform: uppercase;
		&:after {
			content:"";
			display:block;
			position:absolute;
			bottom:0;
			left:0;
			width:82px;
			height:7px;
			background:url(../images/heading-accent.svg) no-repeat center center;
			background-size:contain;
		}
	}
	.container {
		display:flex;
		flex-wrap:wrap;
		align-items: center;
	}
	.content {
		float:left;
		width:50%;
	}
	.slider {
		float:left;
		width:50%;
		text-align: right;
		position:relative;
		&:after, &:before {
			content:"";
			display:block;
			position:absolute;
			top:0;
			bottom:0;
			right:0;
			width:200px;
			background: rgb(255,255,255);
			background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			z-index:3;
			pointer-events: none;
		}
		&:before {
			left:0;
			right:auto;
			background: rgb(255,255,255);
			background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
		}
		.member {
			padding:10px 25px;
			display:flex !important;
			align-items: center;
			img {
				max-height:100px;
			}
		}
	}
}

footer {
	background:#f6f6f6 url(../images/footer-bg.png) no-repeat top left;
	background-size:auto 80%;
	font-size:14px;
	img.alignleft, img.aligncenter, img.alignright {
		padding:0;
	}
	img.alignleft {
		margin-right: 3px;
	}
	h4 {
		font-size:16px;
		font-weight:700;
		text-transform: uppercase;
	}
	.top {
		padding:50px 0;
		.column {
			float:left;
			width:25%;
			padding-right: 100px;
			&:first-child {
				width:50%;
			}
			&:last-child {
				padding-right: 0;
			}
		}
		nav {
			ul {
				margin:0;
				padding:0;
				li {
					position:relative;
					margin-bottom: 10px;
					padding-left: 15px;
					&:before {
						content:"";
						display:block;
						position:absolute;
						top:50%;
						left:0;
						transform:translateY(-50%);
						width:6px;
						height:6px;
						background:url(../images/play-grey.svg) no-repeat center center;
						background-size:contain;
					}
					a {
						color:$font-color;
						&:hover {
							color:$link-color;
						}
					}
				}
			}
		}
	}
	.bottom {
		border-top:1px solid #ddd;
		padding:20px 0;
		color:#969696;
		.left {
			float:left;
		}
		.right {
			float:right;
			a {
				float:left;
				display:inline-block;
				width:18px;
				height:18px;
				background:no-repeat center center;
				background-size:contain;
				filter: grayscale(100%);
				transition:all 0.3s;
				margin-left: 10px;
				opacity:0.7;
				&:hover {
					opacity:1;
					filter: grayscale(0%);
				}
			}
			a.facebook {
				background-image:url(../images/facebook.svg);
			}
			a.twitter {
				background-image:url(../images/twitter.svg);
			}
			a.linkedin {
				background-image:url(../images/linkedin.svg);
			}
			a.youtube {
				margin-top: -2px;
				width:23px;
				height:23px;
				background-image:url(../images/youtube.svg);
			}
		}
	}
}

.featured-event {
	overflow: hidden;
	padding-bottom: 0;
	.container {
		margin-top: 35px;
		display:flex !important;
	}
	h2 {
		font-weight:700;
		position:relative;
		padding-bottom: 20px;
		text-transform: uppercase;
		&:after {
			content:"";
			display:block;
			position:absolute;
			bottom:0;
			left:0;
			width:82px;
			height:7px;
			background:url(../images/heading-accent.svg) no-repeat center center;
			background-size:contain;
		}
	}
	.content {
		float:right;
		background:#fff;
		padding:40px 0 40px 40px;
		width:40%;
		position:relative;
		z-index:2;
		margin-top:auto;
		.date {
			color:$link-color;
		}
		h3 {
			margin-top: 0;
			text-transform: uppercase;
			a {
				color:$font-color;
				font-size:24px;
				line-height:1.3;
				&:hover {
					color:$link-color;
				}
			}
		}
		.featured-event-categories {
			border-top: 1px solid #b3b3b3;
			padding:10px 0;
			font-size:14px;
			color:#b3b3b3;
			a {
				color:#b3b3b3;
				&:hover {
					color:$link-color;
				}
			}
		}
	}
	.image {
		float:left;
		width:60%;
		padding-bottom: 40%;
		position:relative;
		margin-bottom:60px;
		a:hover {
			.img-resize {
				&:after {
					box-shadow: inset 0px 0px 0px 15px rgba(255,255,255,1);
				}
				&:before {
					background-size: 30% auto;
				}
			}
		}
		.img-resize {
			position:absolute;
			top:0;
			left:0;
			right:-120px;
			bottom:0;
			overflow: hidden;
			border:1px solid $link-color;
			overflow: hidden;
			&:before {
				content: "";
			    display: block;
			    position: absolute;
			    top: 0;
			    left: 0;
			    right: 0;
			    bottom: 0;
			    background: url(../images/logo-mark.svg) no-repeat 50%;
			    background-size: 25% auto;
			    transition: all .3s;
			}
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				box-shadow: inset 0px 0px 0px 10px rgba(255,255,255,1);
				transition:all 0.3s;
				z-index:2;
			}
			img {
				max-width:none;
				position:relative;
				z-index:1;
			}
		}
	}
}

.breadcrumbs {
	background: no-repeat center center;
	background-size:cover;
	padding:70px 0;
	text-align: center;
	color:#fff;
	position:relative;
	&:before {
		content:"";
		display:block;
		position:absolute;
		bottom:0;
		right:0;
		width:128px;
		height:103px;
		background:url(../images/hero-slider-mask.png) no-repeat bottom right;
		background-size:contain;
		z-index:1;
	}
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:#000;
		opacity:0.3;
	}
	.pageAlign {
		position:relative;
		z-index:1;
	}
	a {
		color:#fff;
		&:hover {
			color:$link-color;
		}
	}
	h1 {
		margin-top: 0;
		font-size:48px;
		font-weight:700;
		position:relative;
		&:after {
			content:"";
			display:block;
			position:absolute;
			bottom:-10px;
			left:50%;
			transform:translateX(-50%);
			width:82px;
			height:7px;
			background:url(../images/heading-accent-white.svg) no-repeat center center;
			background-size:contain;
		}
	}
	.breadcrumb {
		.seperator {
			margin:0 15px;
		}
	}
}

.newsletter {
	text-align: center;
	margin:0;
	position:relative;
	padding:8rem 0;
	background:no-repeat center center;
	background-size:cover;
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:#000;
		opacity:0.5;
	}
	.pageAlign {
		position:relative;
		z-index:1;
	}
}

.single-post-wrapper {
	.post-category {
		float:left;
		width:30%;
		h2 {
			font-weight:700;
			position:relative;
			padding-bottom: 20px;
			text-transform: uppercase;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:0;
				left:0;
				width:82px;
				height:7px;
				background:url(../images/heading-accent.svg) no-repeat center center;
				background-size:contain;
			}
		}
	}
	.single-post-content {
		width:70%;
		margin:0 auto;
		ul {
			li {
				margin:5px 0;
			}
		}
		.lightweight-accordion details[open] {
			.lightweight-accordion-title {
				h3 {
					color:$link-color;
				}
			}
		}
		.lightweight-accordion {
			border-bottom: 1px solid #ccc;
			margin-bottom:0;
			.lightweight-accordion-title {
				background:transparent;
				h3 {
					font-weight:700;
					font-size:16px;
				}
			}
		}
		.accorditions {
			padding:30px 0 70px 0;
			.accordition {
				.title {
					text-transform: uppercase;
					font-weight:700;
					transition:all 0.3s;
					padding:18px 20px 18px 0;
					border-bottom: 1px solid #ccc;
					cursor:pointer;
					position:relative;
					&.active {
						color:$link-color;
						&:after {
							transform:translateY(-50%) rotate(270deg);
						}
					}
					&:after {
						content:"";
						display:block;
						position:absolute;
						top:50%;
						right:0;
						width:10px;
						height:10px;
						background:url(../images/arrow-black.svg) no-repeat center center;
						background-size:contain;
						transform:translateY(-50%) rotate(90deg);
						opacity:0.4;
						transition:all 0.3s;
					}
					&:hover {
						color:$link-color;
					}
				}
				.content {
					display:none;
				}
			}
		}
		.top {
			position:relative;
			@include clearfix;
			.left {
				position:absolute;
				top:0;
				left:0;
				float:left;
				width:60px;
				height:85px;
				background:$link-color;
				text-align: center;
				color:#fff;
				&.both {
					background:#6ab961;
				}
				&.standard {
					background:$link-color;
				}
				&.online {
					background:$blue-color;
				}
				div {
					display:block;
					.day {
						font-size:30px;
					}
					.month {
						text-transform: uppercase;
						font-size:15px;
					}
					.year {
						font-size:13px;
						border-top: 1px solid #fff;
						padding-top: 4px;
						margin-top: 2px;
					}
				}
			}
			.right {
				&.is-event {
					padding-left: 100px;
					float:left;
				}
				h1 {
					margin-top: 0;
					color:$font-color;
				}
				.post-details {
					@include clearfix;
					margin-bottom: 15px;
					div {
						position:relative;
						float:left;
						margin:5px 15px 5px 0;
						padding-left:25px;
						font-size:14px;
						color:#aaaaaa;
						&:last-child {
							margin-right: 0;
						}
						a {
							color:#aaa;
							&:hover {
								color:$link-color;
							}
						}
						&:before {
							content:"";
							display:block;
							position:absolute;
							left:0;
							top:50%;
							transform:translateY(-50%);
							width:17px;
							height:17px;
							opacity:0.5;
							background:no-repeat center center;
							background-size:contain;
						}
						&.post-categories {
							span:last-child {
								display:none;
							}
						}
						&.post-date:before {
							background-image:url(../images/icon-calendar.svg);
						}
						&.post-categories:before {
							background-image:url(../images/icon-folder.svg);
						}
						&.event-type:before {
							background-image:url(../images/icon-event-type.svg);
						}
					}
					a.button {
						float:right;
						margin:-13px 0 0 0;
					}
				}
			}
		}
		h3 {
			font-size:18px;
			line-height:26px;
		}
		img.wp-post-image {
			margin:20px 0;
		}
	}
}

.more-posts {
	background:#fbfbfb;
	overflow: hidden;
	h2 {
		font-weight:700;
		position:relative;
		padding-bottom: 20px;
		text-transform: uppercase;
		&:after {
			content:"";
			display:block;
			position:absolute;
			bottom:0;
			left:0;
			width:82px;
			height:7px;
			background:url(../images/heading-accent.svg) no-repeat center center;
			background-size:contain;
		}
	}
	.container {
		display:flex;
		flex-wrap:wrap;
		margin:0 -20px;
	}
	.box {
		float:left;
		width:33.3333%;
		padding:20px 20px 50px 20px;
		&.type-event {
			.image {
				.img-resize {
					left:78px;
				}
			}
		}
		a, span {
			display:block;
		}
		.image {
			height:0;
			padding-bottom: 56.25%;
			position:relative;
			background:#fff;
			overflow: hidden;
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background:url(../images/logo-mark.svg) no-repeat center center;
				background-size:50px auto;
				transition:all 0.3s;
			}
			.img-resize {
				position:absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				z-index:1;
				overflow: hidden;
				img {
					max-width:none;
					transition:transform .7s;
				}
			}
			.event-info {
				position:absolute;
				top:0;
				left:0;
				bottom:0;
				width:78px;
				background:$link-color;
				z-index:2;
				color:#fff;
				text-align: center;
				color:#fff;
				display:flex;
				align-items: flex-start;
				flex-wrap: wrap;
				&.online {
					background:$blue-color;
					.event-icon {
						background-image:url(../images/icon-event-web.svg);
					}
				}
				&.standard {
					background:$link-color;
					.event-icon {
						background-image:url(../images/icon-event-standard.svg);
					}
				}
				&.both {
					background:#6ab961;
					.event-icon {
						background-image:url(../images/icon-event-both.svg);
					}
				}
				.date {
					width:100%;
				}
				.event-icon {
					width:100%;

				}
				.day {
					font-size:30px;
				}
				.month {
					text-transform: uppercase;
					font-size:15px;
				}
				.year {
					font-size:13px;
					border-top: 1px solid #fff;
					padding-top: 4px;
					margin-top: 4px;
				}
			}
			.event-icon {
				margin-top:auto;
				height:0;
				padding-bottom: 60%;
				margin-bottom: 5px;
				background:yellow;
				position:relative;
				background:no-repeat top center;
				background-size:auto 85%;
			}
			&:hover {
				&:after {
					background-size:70px auto;
				}
				img {
					transform:scale(1.1);
				}
			}
		}
		.title {
			color:$font-color;
			font-size:16px;
			font-weight:600;
			padding-top: 25px;
			transition:all 0.3s;
			text-transform: uppercase;
			&:hover {
				color:$link-color;
			}
		}
	}
}

.post-type-archive-team {
	.shared-value {
		.left {
			width:70;
		}
		.right {
			width:30%;
			text-align: center;
			img {
				display:inline-block;
				width:auto;
				max-height:500px;
			}
		}
	}
}
.shared-value {
	.container {
		display:flex;
		flex-wrap:wrap;
		align-items:center;
	}
	.left {
		float:left;
		width:55%;
		padding-right: 100px;
		h2 {
			font-weight:700;
			position:relative;
			padding-bottom: 20px;
			text-transform: uppercase;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:0;
				left:0;
				width:82px;
				height:7px;
				background:url(../images/heading-accent.svg) no-repeat center center;
				background-size:contain;
			}
		}
	}
	.right {
		float:right;
		width:45%;
		img {
			width:100%;
			height:auto;
		}
	}
}

.footprint {
	.container {
		display:flex;
		flex-wrap:wrap;
		align-items:center;
	}
	.left {
		float:left;
		width:60%;
		padding-right: 50px;
	}
	h2 {
		font-weight:700;
		position:relative;
		padding-bottom: 20px;
		text-transform: uppercase;
		&:after {
			content:"";
			display:block;
			position:absolute;
			bottom:0;
			left:0;
			width:82px;
			height:7px;
			background:url(../images/heading-accent.svg) no-repeat center center;
			background-size:contain;
		}
	}
	.right {
		float:right;
		width:40%;
		img {
			width:100%;
			height:auto;
		}
	}
}

.impact {
	h2 {
		font-weight:700;
		position:relative;
		padding-bottom: 20px;
		text-transform: uppercase;
		&:after {
			content:"";
			display:block;
			position:absolute;
			bottom:0;
			left:50%;
			width:82px;
			height:7px;
			transform:translateX(-50%);
			background:url(../images/heading-accent-white.svg) no-repeat center center;
			background-size:contain;
		}
	}
	.impacts {
		@include clearfix;
		display:flex;
		flex-wrap:wrap;
		justify-content: center;
		.col20 {
			width:20%;
			text-align: center;
			font-weight:600;
			text-transform: uppercase;
			p {
				line-height:normal;
			}
			strong {
				font-size:50px;
				display:block;
			}

		}
	}
}

.shared-value-initiative {
	.container {
		display:flex;
		flex-wrap:wrap;
		.box {
			float:left;
			width:50%;
		}
		.icon {
			float:left;
			width:10%;
			height:0%;
			padding-bottom: 13%;
			background:no-repeat top center;
			background-size: contain;
		}
		.content {
			float:left;
			width:70%;
			padding-left: 30px;
		}
	}
}

.footprint-impact {
	overflow: hidden;
	padding:0;
	h2 {
		text-transform: uppercase;
		font-size:32px;
	}
	img {
		width:auto;
		height:auto;
	}
	.container {
		display:flex;
		flex-wrap:wrap;
		align-items: stretch;
	}
	.left {
		padding:40px 0;
		width:50%;
		text-align: right;
		display:flex;
		flex-wrap:wrap;
		align-items: center;
		img {
			margin-bottom: auto;
			padding-right: 40px;
		}
		h2 {
			margin:0 0 auto 0;
			text-align: right;
			width:100%;
		}
	}
	.right {
		width:50%;
		text-align: left;
		display:flex;
		align-items: stretch;
		flex-wrap:wrap;
		h2 {
			margin:0 0 auto 0;
		}
		.lists {
			width:100%;
			color:#fff;
			background:$link-color;
			padding:40px 0;
			position:relative;
			h3, p {
				padding-left: 30px;
			}
			&:after {
				content:"";
				display:block;
				position:absolute;
				left:100%;
				top:0;
				bottom:0;
				width:900%;
				background:$link-color;
			}
			ul {
				list-style:none;
				margin:0;
				padding:20px 0 20px 30px;
				float:left;
				width:50%;
				li {
					margin:10px 0;
				}
			}
		}
		.impact-image {
			width:100%;
			padding:40px 0;
			background:#fff;
			position:relative;
			&:after {
				content:"";
				display:block;
				position:absolute;
				left:100%;
				top:0;
				bottom:0;
				width:900%;
				background:#fff;
			}
		}
	}
}

.cta-banner {
	background:no-repeat center center;
	background-size:cover;
	text-align: center;
	color:#fff;
	position:relative;
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background:#000;
		opacity:0.3;
	}
	.container {
		margin:0 auto;
		max-width:790px;
		position:relative;
		z-index:1;
	}
	p {
		font-size:28px;
		font-weight:600;
		line-height:1.7;
	}
}

.quote-section {
	text-align: center;
	.container {
		margin:0 auto;
		max-width:790px;
	}
	blockquote {
		p {
			font-size:28px;
			text-transform: uppercase;
			font-weight:600;
			line-height:1.5;
			&:before {
				content:"“ ";
			}
			&:after {
				content:" ”";
			}
		}
	}
}

.the-team {
	padding-top: 0;
	overflow: hidden;
	.tabs {
		background:#fff;
		border-bottom: 1px solid #f7f7f7;
		.container {
			@include clearfix;
		}
		.tab {
			float:left;
			padding:20px 15px;
			cursor:pointer;
			transition:all 0.3s;
			background:#f7f7f7;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			margin-right: 1px;
			&.active {
				background:$link-color;
				color:#fff;
				&:hover {
					color:#fff;
				}
			}
			&:hover {
				color:$link-color;
			}
		}
	}
	.tab-content-wrapper {
		.tab-content {
			padding-top: 50px;
			display:none;
			&:first-child {
				display:block;
			}
			h2 {
				font-weight:700;
				position:relative;
				padding-bottom: 20px;
				&:after {
					content:"";
					display:block;
					position:absolute;
					bottom:0;
					left:0;
					width:82px;
					height:7px;
					background:url(../images/heading-accent.svg) no-repeat center center;
					background-size:contain;
				}
			}
			.container {
				margin:0 -20px;
				display:flex;
				flex-wrap:wrap;
				.box {
					float:left;
					width:25%;
					padding:20px;
					.inside {
						height:100%;
						background:#fff;
						a:first-child {
							&:hover {
								.name {
									color:$link-color;
								}
								.image {
									.img-resize {
										&:before {
											opacity:1;
										}
										&:after {
											opacity:0.2;
										}
									}
								}
							}
						}
						span {
							display:block;
						}
						.image {
							height:0;
							padding-bottom: 65%;
							background:#fff;
							position:relative;
							&:after {
								content:"";
								display:block;
								position:absolute;
								top:0;
								left:0;
								right:0;
								bottom:0;
								background:url(../images/logo-mark.svg) no-repeat center center;
								background-size:50px auto;
								transition:all 0.3s;
							}
							.img-resize {
								position:absolute;
								top:0;
								left:0;
								right:0;
								bottom:0;
								overflow: hidden;
								z-index:1;
								&:after {
									content:"";
									display:block;
									position:absolute;
									top:0;
									left:0;
									right:0;
									bottom:0;
									background:#000;
									opacity:0;
									transition:all 0.3s;
								}
								&:before {
									content:"+";
									display:block;
									position:absolute;
									top:50%;
									left:50%;
									transform:translateX(-50%);
									font-size:70px;
									color:#fff;
									font-weight:700;
									z-index:2;
									height:40px;
									width:40px;
									line-height:40px;
									margin-top: -20px;
									opacity:0;
									transition:all 0.3s;
								}
								img {
									max-width:none;
								}
							}
						}
						.name {
							text-transform: uppercase;
							color:$font-color;
							font-weight:700;
							padding:15px 15px 0 15px;
							transition:all 0.3s;
						}
						.country {
							padding:5px 15px 15px 15px;
							text-transform: uppercase;
							font-size:12px;
							color:#666;
						}
						.content {
							padding:0 15px;
							font-size:14px;
							color:#666;
						}
						a:last-child {
							font-size:12px;
							padding:5px 15px 20px 15px;
							display:inline-block;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
}
.single-team {
	.single-post-content {
		h1 {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
	.country {
		padding:0 0 10px 0;
		text-transform: uppercase;
		font-size:12px;
		color:#666;
	}
	.image {
		a {
			display:block;
			position:relative;
			&:hover {
				&:before {
					opacity:0.7;
				}
			}
			&:after {
				content:"";
				display:block;
				position:absolute;
				width:50px;
				height:50px;
				top:20px;
				left:20px;
				background:url(../images/play.svg) no-repeat center center;
				background-size:30%;
				z-index:3;
			}
			&:before {
				content:"";
				display:block;
				position:absolute;
				width:50px;
				height:50px;
				border-radius:100%;
				top:20px;
				left:20px;
				background:#fff;
				z-index:2;
				transition:all 0.3s;
			}
		}
	}
}

.column-section {
	overflow: hidden;
	.container {
		@include clearfix;
		margin:0 -20px;
	}
	.column {
		float:left;
		padding:20px;
		hr {
			display: block;
		    clear: both;
		    margin-top: 20px;
		    margin-bottom: 20px;
		    width: 100%;
		    height: 1px;
		    border: 0;
		    background: #E8E8E8;
		}
		ol, ul {
			li {
				margin:10px 0;
			}
		}
	}
	&.one-column {
		.column {
			width:100%;
		}
	}
	&.two-column {
		overflow: hidden;
		.container {
			display:flex;
			flex-wrap:wrap;
		}
		.column {
			width:50%;
			position:relative;
			p, ul, blockquote, ol, span, h1,h2,h3,h4,h5 {
				position:relative;
				z-index:2;
			}
			&:before {
				content:"";
				display:block;
				background-color:inherit;
				position:absolute;
				right:100%;
				width:9000000000px;
				top:0;
				bottom:0;
				margin:0 -2px 0 0;
			}
			&:last-child {
				&:before {
					content:"";
					display:block;
					background-color:inherit;
					position:absolute;
					left:100%;
					width:9000000000px;
					top:0;
					bottom:0;
					margin:0 0 0 -2px;
				}
			}
		}
	}
	&.three-column {
		.column {
			width:33%;
		}
	}
	&.four-column {
		.column {
			width:25%;
		}
	}
	&.five-column {
		.column {
			width:20%;
		}
	}
}

section {
	div.wpcf7 {
		form {
			input[type="text"], input[type="email"], input[type="tel"], input[type="password"] {
				height:38px;
				border:1px solid #ccc;
				line-height:38px;
				padding: 6px 12px;
			    font-size: 14px;
			    line-height: 1.42857143;
			    color: #555;
			    background-color: #fff;
			    background-image: none;
				width:100%;
				&:focus {
					border-color:$link-color;
				}
			}
			input[type="file"] {
				margin:10px 0;
			}
			textarea {
				height:38px;
				min-height:80px;
				max-height:500px;
				border:1px solid #ccc;
				line-height:38px;
				padding: 6px 12px;
				font-size: 14px;
				line-height: 1.42857143;
				color: #555;
				background-color: #fff;
				background-image: none;
				width:100%;
				min-width:100%;
				max-width:100%;
				&:focus {
					border-color:$link-color;
				}
			}
			.selectric-wrapper {
				margin:0;
				.selectric {
					width:100%;
				}
			}
		}
		.row {
			@include clearfix;
			margin:0 -10px;
			.col-md-2 {
				float:left;
				width: 16.66666667%;
				padding:10px;
			}
			.col-md-5 {
				float:left;
			    width: 41.66666667%;
				padding:10px;
			}
			.col-md-6 {
				float:left;
				width:50%;
				padding:10px;
			}
			.col-md-12 {
				float:left;
				width:100%;
				padding:10px;
			}
			.form-group {
				//margin:10px 0;
			}
		}
	}
}

.google-map {
	height:0;
	padding-bottom: 20%;
	position:relative;
	iframe {
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		width:100%;
		height:100%;
	}
}

.big-text {
	p {
		font-size:24px;
		line-height:1.4;
		font-weight:400;
	}
}

.shared-value-awards {
	.top {
		h2 {
			font-weight:700;
			position:relative;
			padding-bottom: 20px;
			text-transform: uppercase;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:0;
				left:0;
				width:82px;
				height:7px;
				background:url(../images/heading-accent.svg) no-repeat center center;
				background-size:contain;
			}
		}
	}
	.content {
		.award {
			border-bottom: 1px solid #ececec;
			padding:20px 0;
			@include clearfix;
			display:flex;
			flex-wrap:wrap;
			align-items: center;
			.image {
				float:left;
				width:30%;
				height:0;
				padding-bottom: 22%;
				position:relative;
				.img-resize {
					position:absolute;
					top:0;
					left:0;
					right:0;
					bottom:0;
					overflow: hidden;
					img {
						max-width:none;
					}
				}
			}
			.award-content {
				float:right;
				width:70%;
				padding-left: 60px;
			}
		}
	}
}

.members-list {
	overflow: hidden;
	background: #f8f8f8;
	padding:80px 0;
	.member-category {
		text-align: center;
		margin-bottom: 80px;
		&:last-child {
			margin-bottom: 0;
		}
		h2 {
			font-weight:700;
			position:relative;
			padding-bottom: 20px;
			text-transform: uppercase;
			margin-bottom: 0;
			text-align: center;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:0;
				left:50%;
				transform:translateX(-50%);
				width:82px;
				height:7px;
				background:url(../images/heading-accent.svg) no-repeat center center;
				background-size:contain;
			}
		}
		.member-wrapper {
			@include clearfix;
			margin:0 -20px;
			display:flex;
			flex-wrap:wrap;
			justify-content: center;
		}
		.member {
			float:left;
			//padding:20px;
			img {
				transition:all 0.3s;
			}
		}
		a:hover {
			img {
				opacity:0.7;
			}
		}

	}
}

.work-deliverables {
	text-align: center;
	.top {
		border-top: 1px solid #ccc;
		padding: 30px 0;
		margin-top: 30px;
		h2 {
			font-weight:700;
			position:relative;
			padding-bottom: 40px;
			text-transform: uppercase;
			&:after {
				content:"";
				display:block;
				position:absolute;
				bottom:0;
				left:50%;
				transform:translateX(-50%);
				width:82px;
				height:7px;
				background:url(../images/heading-accent.svg) no-repeat center center;
				background-size:contain;
			}
		}
		.text {
			margin:0 auto;
			max-width:800px;
		}
	}
	.container {
		.slider {
			padding-top: 30px;
			position:relative;
			&:after, &:before {
				content:"";
				display:block;
				position:absolute;
				top:0;
				bottom:0;
				right:0;
				width:200px;
				background: rgb(255,255,255);
				background: linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
				z-index:3;
			}
			&:before {
				left:0;
				right:auto;
				background: rgb(255,255,255);
				background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			}
			.slide {
				width:160px;
				text-align: center;
				margin:0 30px;
				.image {
					margin:0 auto;
					width:100px;
					height:100px;
					display:flex;
					justify-content: center;
					align-items: center;
					img {
						max-height:100%;
					}
				}
				.text {
					color:$blue-color;
				}
			}
		}
	}
}

.membership-engagement {
	h2 {
		font-weight:700;
		position:relative;
		padding-bottom: 20px;
		color:$link-color;
		text-transform: uppercase;
		&:after {
			content:"";
			display:block;
			position:absolute;
			bottom:0;
			left:0;
			width:82px;
			height:7px;
			background:url(../images/heading-accent.svg) no-repeat center center;
			background-size:contain;
		}
	}
	.container {
		margin:0 -10px;
	}
	.left {
		float:left;
		width:50%;
	}
	.right {
		float:right;
		width:50%;
	}
	.accordition {
		float:left;
		width:100%;
		padding:0 10px;
		.title {
			text-transform: uppercase;
			font-weight:600;
			transition:all 0.3s;
			padding:18px 20px 18px 0;
			border-bottom: 1px solid #ccc;
			cursor:pointer;
			position:relative;
			&.active {
				color:$link-color;
				&:after {
					transform:translateY(-50%) rotate(270deg);
				}
			}
			&:after {
				content:"";
				display:block;
				position:absolute;
				top:50%;
				right:0;
				width:10px;
				height:10px;
				background:url(../images/arrow-black.svg) no-repeat center center;
				background-size:contain;
				transform:translateY(-50%) rotate(90deg);
				opacity:0.4;
				transition:all 0.3s;
			}
			&:hover {
				color:$link-color;
			}
		}
		.content {
			display:none;
		}
	}
}

.our-members-cta {
	color:#fff;
	text-align: center;
	h2 {
		font-weight:700;
		position:relative;
		padding-bottom: 20px;
		text-transform: uppercase;
		color:#fff;
		&:after {
			content:"";
			display:block;
			position:absolute;
			bottom:0;
			left:50%;
			transform:translateX(-50%);
			width:82px;
			height:7px;
			background:url(../images/heading-accent-white.svg) no-repeat center center;
			background-size:contain;
		}
	}
	.member {
		display:inline-block;
		padding:5px 20px;
		width:140px;
		img {
			filter: brightness(0) invert(1);
		}
	}
}

.cta {
	text-align: center;
	.icon {
		width:110px;
		height:110px;
		display:inline-block;
		background:no-repeat center center;
		background-size:contain;
	}
	h2 {
		text-transform: uppercase;
		font-weight:700;
	}
}

.custom-quote {
	color:$link-color;
	border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
	margin:30px 0;
	padding:10px;
	text-align: center;
	p:first-child {
		&::first-letter {
			font-size:42px;
		}
	}
	p {
		font-size: 22px;
	    line-height: normal;
	    max-width: 900px;
	    margin: 15px auto;
		strong {
			font-size:16px;
			font-weight:400;
		}
	}
}

form {
	.selectric-wrapper {
		display:block;
	}
	.selectric {
		min-width:100% !important;
	}
}

.filters {
	padding:30px 0;
	@include clearfix;
	.selectric-wrapper {
		float:left;
	}
	.filters-wrapper {
		.filters-toggle {
			display:none;
		    position: relative;
		    background: #f05b31;
		    color: #fff;
		    padding: 15px;
			cursor:pointer;
			&.active {
				&:before {
					transform: translateY(-50%) rotate(-90deg);
				}
			}
			&:before {
				content: "";
			    display: block;
			    position: absolute;
			    top: 50%;
			    right: 10px;
			    transform: translateY(-50%) rotate(90deg);
			    background: url(../images/arrow-white.svg) no-repeat 50%;
			    background-size: contain;
			    width: 10px;
			    height: 10px;
			    opacity: 1;
			    transition: all .3s;
			}
		}
	}
}

.two-column-big-image {
	@include clearfix;
	position:relative;
	&.reverse-order {
		.image {
			left:auto;
			right:0;
		}
		.container {
			padding-left: 0;
			float:left;
			padding-right: 50px;
		}
	}
	.image {
		position:absolute;
		top:0;
		left:0;
		bottom:0;
		width:50%;
		background:no-repeat center center;
	}
	.container {
		float:right;
		width:50%;
		padding-left: 50px;
	}
	ul {
		li {
			margin: 10px 0;
		}
	}
}
html .formcraft-css .fc-form {
	box-shadow:none !important;
}
html .formcraft-css .fc-form.align-center {
	width:auto !important;
}
html .formcraft-css .fc-form .form-page-content {
	padding:0 !important;
}
html .formcraft-css .fc-form .field-cover>span {
	width:auto !important;
	margin-bottom: 5px;
}
html .formcraft-css .fc-form .form-element .field-cover.customText-cover h3 {
	color:#111 !important;
}

@import "mobile";
