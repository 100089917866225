@import "mixin";

$base-font-size: 16px; /*DEFINE WEBSITE COLORS*/
$link-color: #F05B31; //change this
$blue-color: #083957;

$font-color:#111;

/*DEFINE CUSTOM FONTS (remove if not needed)*/
.scroll-to-top {
	width:40px;
	height:40px;
	position:fixed;
	z-index:10;
	bottom:15px;
	right:10px;
	background:#3c3c3c;
	transition:all 0.3s;
	cursor:pointer;
	opacity:0;
	visibility: hidden;
	&.active {
		opacity:1;
		visibility: visible;
	}
	&:after {
		content:"";
		display:block;
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		transform:rotate(-90deg);
		background:url(../images/arrow-white.svg) no-repeat center center;
		background-size:30%;

	}
	&:hover {
		background:$link-color;
	}
}
section {
	padding:60px 0;
}

img.aligncenter {
	text-align: center;
	margin:20px auto;
}
img.alignleft {
	float:left;
}
img.alignright {
	float:right;
}

.my-lazyloader-btn-container {
	text-align: center;
	a {
		display:inline-block;
		margin:20px 10px;
		padding:12px 40px;
		color:#fff;
		text-transform: uppercase;
		font-size:14px;
		transition:all 0.3s;
		border-radius:30px;
		position:relative;
		border:1px solid $link-color;
		color:$link-color;
		&:hover {
			color:#fff;
			background:$link-color;
		}
		&.my-lazyloader-btn-disabled {
			display:none;
		}
	}
}
.my-lazyloader-content {
	display:flex;
	flex-wrap:wrap;
}
.my-lazyloader-wrapper {
	width:100%;
}

.addthis_inline_share_toolbox_rnuh {
	margin-top: 50px;
	padding-top: 20px;
	border-top:1px solid #ccc;
}
.at-share-tbx-element .at-share-btn {
	span.at-icon-wrapper {
		width:30px !important;
		height:30px !important;
		border-radius:100%;
		overflow: hidden;
		svg {
			margin:5px;
		}
	}
}

.selectric-wrapper {
	display:inline-block;
	margin-right: 10px;
	.selectric {
		min-width:140px;
		background:transparent;
		transition:border-color 0.3s;
	}
	.button {
		background:transparent;
	}
	.selectric-items {
		background:#fff;
		border-color:#e2e2e2;
		box-shadow:none;
		margin-top: -1px;
		li.selected {
			background:$link-color;
			color:#fff;
			&:hover {
				background:$link-color;
			}
		}
		li:hover {
			background:#e6e6e6;
		}
	}
}
.selectric-hover {
	.selectric {
		border-color:$link-color;
	}
}

.post-type-archive {
	.home-news-events {
		.container {
			display:block;
			margin:0;
		}
		.my-lazyloader-content {
			display:flex;
			flex-wrap:wrap;
			margin:0 -20px;
		}
	}
}

.pagination {
	border-top:1px solid #ccc;
	display:flex;
	flex-wrap:wrap;
	justify-content: center;
	padding-top: 15px;
	font-size:14px;
	a, span {
		padding:12px;
		display:inline-block;
		background:#fff;
		color:$font-color;
		transition:all 0.3s;
	}
	span.page-numbers {
		color:#ccc;
	}
	a.page-numbers {
		border-radius:100%;
		&:hover {
			background:$link-color;
			color:#fff;
		}
	}
	a.page-numbers ,span.page-numbers {
		margin:0 3px;
		border-radius:100%;
		padding:0;
		width:41px;
		height:41px;
		line-height:41px;
		text-align: center;
	}
	a.prev, a.next {
		background:$link-color;
		border-radius:30px;
		padding:12px 40px;
		color:#fff;
		width:auto;
		height:auto;
		line-height:normal;
		&:hover {
			background:darken($link-color, 10%);
		}
	}
}


/*START TEMPLATE STYLES*/
a,
img {
	border: none;
	text-decoration: none;
	outline: 0;
}

img {
	display: block;
	max-width: 100%;
}

*:focus {
	outline: 0;
}

* {
	@include box-sizing;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700; //change this if not using google fonts
}
// Remove if we DON'T use Google fonts
footer ul,
header ul {
	list-style: none;
}

fieldset {
	border: 0;
}

input {
	padding: 0;
	margin: 0;
}

a {
	color: $link-color;
	@include color-transition;

	&:hover {
		color: $font-color;
	}
}

.pageAlign {
	margin: 0 auto;
	max-width: 1250px;
	padding: 0 50px;
}

.pageAlign2 {
	margin: 0 auto;
	max-width: 1050px;
	padding: 0 50px;
}

.pageAlignFluid {
	margin: 0 auto;
	max-width: 1800px;
	padding: 0 50px;
}

.relative {
	position: relative;
}

.fullWidth {
	float: left;
	width: 100%;
}

.invisible {
	display: none;
}

.fl {
	float: left;
}

.fr {
	float: right;
}

html {
	font-size: 62.5%;
	height: 100%;
}

body,
input,
select,
textarea {
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	/*REMOVE if we DON'T use Google fonts*/
}

body {
	width: 100%;
	padding: 0;
	margin: 0;
	min-height: 100%;
	height: 100%;
	min-width: 100%;
	color: $font-color;
	font-size: $base-font-size;
	background: #fff;
}

p {
	margin-bottom: 3rem;
	//line-height: 1.5;
}

::selection {
	background: $link-color;
	/* WebKit/Blink Browsers */
	color: #fff;
}

::-moz-selection {
	background: $link-color;
	/* Gecko Browsers */
	color: #fff;
}

.responsive-nav {
	display: none;
	margin: 0 auto;
	color: $link-color;
	cursor: pointer;
	position: absolute;
	top: 20px;
	right: 20px;
	@include all-transition;
	button {
		padding: 7px 10px;
		background: none;
		border: none;
		position: relative;
		height: 40px;
		width: 51px;
		cursor: pointer;
		outline: none;
		@include all-transition;
		float: right;
		&:hover {
			.line {
				background: $link-color;
			}
		}
	}
}

.line {
	position: absolute;
	height: 3px;
	width: 30px;
	background: $link-color;
	left: 10px;
	@include all-transition;
}

.line-t {
	top: 9px;
}

.line-m {
	top: 19px;
}

.line-b {
	top: 29px;
}

.menu-on .line-m,
.menu-on:hover .line-m {
	background: transparent;
	opacity:0;
}

.menu-on .line {
	top: 18px;
}

.menu-on .line-t {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.menu-on .line-b {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
